@font-face {
  font-family: 'Oxanium';
  src: url('../../public/fonts/Oxanium/multi/Oxanium-Regular.eot');
  src: url('../../public/fonts/Oxanium/multi/Oxanium-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/Oxanium/multi/Oxanium-Regular.woff2') format('woff2'),
  url('../../public/fonts/Oxanium/multi/Oxanium-Regular.woff') format('woff'),
  url('../../public/fonts/Oxanium/multi/Oxanium-Regular.ttf') format('truetype'),
  url('../../public/fonts/Oxanium/multi/Oxanium-Regular.svg#Oxanium-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Oxanium';
  src: url('../../public/fonts/Oxanium/multi/Oxanium-Bold.eot');
  src: url('../../public/fonts/Oxanium/multi/Oxanium-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/Oxanium/multi/Oxanium-Bold.woff2') format('woff2'),
  url('../../public/fonts/Oxanium/multi/Oxanium-Bold.woff') format('woff'),
  url('../../public/fonts/Oxanium/multi/Oxanium-Bold.ttf') format('truetype'),
  url('../../public/fonts/Oxanium/multi/Oxanium-Bold.svg#Oxanium-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


/*X-Small	None	<576px*/

/*Small	sm	≥576px*/

/*Medium	md	≥768px*/

/*Large	lg	≥992px*/

/*Extra large	xl	≥1200px*/

/*Extra extra large	xxl	≥1400px*/

/*// X-Small devices (portrait phones, less than 576px)*/

/*@media (max-width: 575.98px) { ... }*/

/*// Small devices (landscape phones, less than 768px)*/

/*@media (max-width: 767.98px) { ... }*/

/*// Medium devices (tablets, less than 992px)*/

/*@media (max-width: 991.98px) { ... }*/

/*// Large devices (desktops, less than 1200px)*/

/*@media (max-width: 1199.98px) { ... }*/


/*// X-Large devices (large desktops, less than 1400px)*/

/*@media (max-width: 1399.98px) { ... }*/
body, html {
  overflow-x: hidden;
  width: 100%;
}
html{
  min-height: -webkit-fill-available; /*safari fix*/
}
body {
  background-color: #000;
  font-family: Oxanium, serif;
  position: relative;
  height: 100%;
  overflow-y: hidden;
  font-weight: 500;
  text-size-adjust: 100%;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;


}

#scr_perks {
  display: flex;
  position: relative;
  bottom: 5px;
}

#scr_tusk {
  display: flex;
  position: relative;
  bottom: 5px;
}

#src_rarity {
  display: flex;
  position: relative;
  bottom: 5px;
}

#src_roadmap {
  display: flex;
  position: relative;
  bottom: 5px;
}

#src_team_section {
  display: flex;
  position: relative;
  bottom: 5px;
}

.bg {
  background: url("../../public/images/bg/bg-main.jpg") no-repeat center bottom;
  background-size: cover;
  z-index: 0;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.navbar-nav {
  padding-left: 3.75rem;
}

/*Mouse Wheel */

.mouse_scroll {
  width: 24px;
  height: 100px;
  position: absolute;
  bottom: 0.4rem;
}


.m_scroll_arrows {
  display: block;
  /*width: 5px;*/
  /*height: 5px;*/
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);

  border-right: 3px solid #B75CFF;
  border-bottom: 3px solid #B75CFF;
  margin: 0 0 3px 4px;

  width: 16px;
  height: 16px;
}


.unu {
  margin-top: 1px;
}

.unu, .doi, .trei {
  -webkit-animation: mouse-scroll 1s infinite;
  -moz-animation: mouse-scroll 1s infinite;
  animation: mouse-scroll 1s infinite;

}

.unu {
  -webkit-animation-delay: .1s;
  -moz-animation-delay: .1s;
  -webkit-animation-direction: alternate;

  animation-direction: alternate;
  /*animation-delay: alternate;*/
}

.doi {
  -webkit-animation-delay: .2s;
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;

  animation-delay: .2s;
  animation-direction: alternate;

  margin-top: -6px;
}

.trei {
  -webkit-animation-delay: .3s;
  -moz-animation-delay: .3s;
  -webkit-animation-direction: alternate;

  animation-delay: .3s;
  animation-direction: alternate;


  margin-top: -6px;
}

.mouse {
  height: 42px;
  width: 24px;
  border-radius: 14px;
  transform: none;
  border: 3px solid #B75CFF;
  top: 170px;
}

.wheel {

  display: block;
  margin: 5px auto;
  background: #B75CFF;
  position: relative;

  height: 4px;
  width: 4px;
  border: 3px solid #B75CFF;
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

.wheel {
  -webkit-animation: mouse-wheel 0.6s linear infinite;
  -moz-animation: mouse-wheel 0.6s linear infinite;
  animation: mouse-wheel 0.6s linear infinite;
}

@-webkit-keyframes mouse-wheel {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}

@-moz-keyframes mouse-wheel {
  0% {
    top: 1px;
  }
  25% {
    top: 2px;
  }
  50% {
    top: 3px;
  }
  75% {
    top: 2px;
  }
  100% {
    top: 1px;
  }
}

@-o-keyframes mouse-wheel {

  0% {
    top: 1px;
  }
  25% {
    top: 2px;
  }
  50% {
    top: 3px;
  }
  75% {
    top: 2px;
  }
  100% {
    top: 1px;
  }
}

@keyframes mouse-wheel {

  0% {
    top: 1px;
  }
  25% {
    top: 2px;
  }
  50% {
    top: 3px;
  }
  75% {
    top: 2px;
  }
  100% {
    top: 1px;
  }
}

@-webkit-keyframes mouse-scroll {

  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes mouse-scroll {

  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes mouse-scroll {

  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes mouse-scroll {

  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}


/*Mint*/

a {
  text-decoration: none;
}

#mint_box {
  background-color: rgba(0, 0, 0, 0.5);
  /*margin-top: 24vh;*/
  width: 27.5rem;
  min-height: 8.0rem;
  padding: 0.1rem;

  text-align: center;
  align-items: center;
}

#mint_box_count {
  color: #fff;
  font-size: 1.65rem;
  margin-top: 0.3rem;
}

#mint_button {
  padding: 0.4rem 7rem;
  font-size: 1.5rem;
  margin-top: 1.625rem;
  font-weight: normal;

}

.btnPurple {
  background-image: linear-gradient(-180deg, #B75CFF, #671AE4);
  border-radius: 6px;
  border-width: 1px;
  color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  text-shadow: 0 1px 0 #9b14b3;
  font-weight: bold;
  pointer-events: auto;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: top;
  transition: box-shadow .2s;
}

.btnPurple:hover {
  box-shadow: rgba(253, 76, 0, 0.5) 0 3px 8px;
  color: #ffffff;
  background: linear-gradient(#a20dbd, #671AE4);
}

.btnDisable {
  background: #0a090a;
  border-radius: 6px;
  border-width: 1px;
  color: #828282 !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  text-shadow: 0 1px 0 gray;
  pointer-events: auto;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: top;
  transition: box-shadow .2s;
  opacity: 0.8;
  cursor: not-allowed;
}

/*.btnDisable:hover {*/
/*    color: #1a1e21 !important;*/
/*}*/

.circle-btn {
  background: linear-gradient(214.02deg, #B75CFF 6.04%, #671AE4 92.95%);
  border-radius: 50%;
  display: inline-block;
}

/*.btnPurple {*/
/*    border-width: 1px;*/
/*    color: #ffffff;*/
/*    border-color: #a511c0;*/
/*    font-weight: bold;*/
/*    border-radius: 10px;*/
/*    background: linear-gradient(214.02deg, #B75CFF 6.04%, #671AE4 92.95%);*/
/*    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);*/
/*    text-shadow: 0 1px 0 #9b14b3;*/
/*}*/

/*.btnPurple:hover {*/
/*    background: linear-gradient(#a20dbd, #c123de);*/
/*    color: white;*/
/*    cursor: pointer;*/
/*}*/

#welcome {
  background: rgb(15, 0, 42);
  background: url("../../public/images/bg/blu_abs.jpg") no-repeat center center;
  background-size: cover;
  color: #BDBDBD;
  font-size: 2.6rem;
  text-align: justify;
  padding: 2.9rem 0 4.3rem;
}

#welcome p {
  text-align: center;
  font-size: 2.5rem;
  padding: 0 10rem;
  max-width: 74rem;
  line-height: 4rem;
  margin: 0 auto;
}

#wl_drop {
  font-size: 2rem;
  font-weight: 500;
}

#wl_date {
  font-weight: bold;
  font-size: 2.625rem;
  max-width: 52rem;
  margin: 0 auto;


}

.small_line {
  width: 4rem;
  height: 0.3rem;
  background-color: #B75CFF;
  display: inline-block;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}

.cl_purple_border{
  border-color: #B75CFF !important ;
}
/*Loader*/

@-webkit-keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.load-p{
  display: flex;
  justify-content: center;
  justify-items: center;
  justify-self: center;
  align-content: center;
  width: 200px;
}
.load-donut {
  width: 3rem;
  height: 3rem;
  margin: 1rem;
  border-radius: 50%;
  border: 0.3rem solid rgba(151, 159, 208, 0.3);
  border-top-color: #af55fc;
  -webkit-animation: 1.5s spin infinite linear;
  animation: 1.5s spin infinite linear;
}
#logo_glow {
  height: 7rem;
  width: 100%;
  max-width: 50rem;
  margin: 0 auto 2rem;
  background: url(../../public/images/bg/glow_line.png) no-repeat center center;
  background-size: contain;
  text-align: center;
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  position: relative;
}

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 18px #B75CFF, inset 0 0 18px #B75CFF;
  }
  50% {
    box-shadow: 0 0 24px #B75CFF, inset 0 0 24px #B75CFF;
  }
  100% {
    box-shadow: 0 0 18px #B75CFF, inset 0 0 18px #B75CFF;
  }
}


#logo_glow > div {
  background-color: #0F0024;
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  filter: blur(3px);
  box-shadow: 0 0 16px #B75CFF, inset 0 0 16px #B75CFF;
  -webkit-animation: pulse 2s linear 1s infinite;
  margin: 0 auto;
}

#logo_glow > img {
  width: 7rem;
  top: -7rem;
  position: relative;
  left: 0;
}

/*colors*/

.cl_purple {
  color: #B75CFF
}

.info_header {
  color: #B75CFF;
  height: 4rem;
  line-height: 4rem;
  background: linear-gradient(90deg, rgba(183, 92, 255, 0) 0%, rgba(183, 92, 255, 0.35) 50.71%, rgba(183, 92, 255, 0) 101.02%);
  text-align: center;
  /*padding-top: 0.35rem;*/

  font-size: 2.5rem;
  font-weight: bold;
  margin-top: 5.375rem;
  margin-bottom: 1.75rem;
  position: relative;
}

.info_header:before {
  content: '';
  display: block;
  width: 40%;
  height: 1px;
  border-radius: 0.25rem;
  margin: 0 auto;
  background: linear-gradient(90deg, rgba(183, 92, 255, 0) 0%, rgb(183 92 255 / 77%) 50.71%, rgba(183, 92, 255, 0) 101.02%);
  position: relative;
  top: -1px;
  right: 4%;
}

.info_header:after {
  content: '';
  display: block;
  width: 40%;
  height: 1px;
  border-radius: 0.25rem;
  margin: 0 auto;
  background: linear-gradient(90deg, rgba(183, 92, 255, 0) 0%, rgb(183 92 255 / 77%) 50.71%, rgba(183, 92, 255, 0) 101.02%);

  position: relative;
  top: -1px;
  left: 4%;
}


.info_box {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) -0.47%, rgba(255, 255, 255, 0.024) 34.83%);
  /* Note: backdrop-filter has minimal browser support */
  color: white;
  border: 1px solid #ffffff1a;
}

.round_cr {
  border-radius: 0.625rem;
}

.round_cr-l {
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}

.v_line {
  border-right: 1px solid #4F4F4F;
}


/*whitelist_box*/

#whitelist_box {
  padding: 0 0 3.7rem 0;
}

#whitelist_box h4 {
  font-size: 2rem;
  font-weight: bold;
  margin-top: 0.7rem;
}

#whitelist_box p {
  font-size: 1.25rem;
}

#perk_line_wrapper {
  padding-left: 10rem;
  padding-right: 1rem;
}

.perk_line {
  margin-top: 2.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #C4C4C4;
  text-transform: uppercase;
  /*text-indent: -3.7rem;*/
  /*margin-left: 4rem;*/
  text-shadow: rgba(182, 92, 255, 0.94) -20px 0 34px;
  display: flex;
}

.perk_line > img {
  margin-right: 1.525rem;
  max-width: 2.5rem;
  flex-grow: 0;
  flex-shrink: 0;
  align-self: start;
}


/*$TUSK_box*/

#tusk_box {
  color: white;
}




#tusk_logo_desktop {
  width: 5.625rem;
  align-items: center;
  align-self: center;
}

#tusk_logo_wrapper_desktop {
  font-size: 1.25rem;
  padding: 2.125rem 1.8125rem 1.5rem 4.125rem;
  flex-grow: 1;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.09) 50%, rgba(255, 255, 255, 0) 50%);
  text-transform: capitalize;
}

#tusk_box_desc_desktop {
  padding: 0 0 0 2.0625rem;
  color: #BDBDBD;
  align-self: center;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 500;
}

#tusk_supply_img {
  flex: 0 0 10rem;
  background: linear-gradient(214.02deg, #B75CFF 6.04%, #671AE4 92.95%);
  border-top-right-radius: 0.625rem;
}

#tusk_supply_img img {
  max-height: 10rem;
}

#tusk_supply_desktop {
  flex: 0 0 11.5625rem;
  font-size: 1rem;
  text-align: center;
  background: linear-gradient(214.02deg, #B75CFF 6.04%, #671AE4 92.95%);
  border-bottom-right-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
}

#tusk_supply_count h4 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 1rem;
}

#tusk_supply_count {
  /*flex-basis: auto;*/
  flex-grow: 1;
}

.h_check {
  white-space: nowrap;
  margin-top: 0.2rem;
  display: flex;
  margin-left: 1rem;
}

.h_check > img {
  max-width: 4rem;
  position: relative;
  right: -1.71875rem;
  z-index: 10;
}

.h_check > div {
  background: linear-gradient(270deg, rgba(183, 92, 255, 0) 0%, #671AE4 82.5%);
  font-size: 1.5rem;
  color: #BDBDBD;
  font-weight: bold;
  padding-left: 3.6rem;
  position: relative;
  z-index: 0;
  display: inline-block;
  flex-grow: 1;
  height: 2.5rem;
  line-height: 2.5rem;
  align-self: center;
  vertical-align: middle;
}

.h_check_des {
  padding-left: 8.8rem;
  padding-right: 3rem;
  font-size: 1.25rem;
  color: #BDBDBD;
  margin-bottom: 1.1rem;
  margin-top: 0.3rem;
}

#rarity_box > .info_box {
  padding: 1.5rem 0.5rem 2rem;
}

.rarity_check_des {
  font-size: 1.25rem;
  color: #BDBDBD;
  max-width: 52rem;
  margin: 0.3rem auto 0;
}

/*.rarity_tabs {*/
/*    background: linear-gradient(90deg, rgba(183, 92, 255, 0) 27.83%, #671AE4 82.5%);*/
/*    border: 1px solid #B75CFF;*/
/*    box-sizing: border-box;*/
/*    padding: 0.4rem 0;*/
/*}*/

.rarity_item {
  margin-top: 1.685rem;
  margin-bottom: 2rem;
  flex-direction: column;
  color: white;
  margin-left: 0.625rem;

}

.rarity_item > div {
  margin-top: 1rem;
  font-weight: bold;
  font-size: 1.125rem;
  color: #BDBDBD;
  /*max-width: 13.687rem;*/
  align-self: stretch;
  text-align: center;
  /*background: linear-gradient(90deg, rgba(183, 92, 255, 0) 27.83%, #671AE4 82.5%);*/
  /*border: 1px solid #B75CFF;*/
  box-sizing: border-box;
  line-height: 2.5rem;
}

.rarity_item > img {
  width: 100%;
  max-width: 9.875rem;
  border-radius: 0.5rem;
}

.neon_gray {
  border: 1px solid #929292;
  box-shadow: inset 0 0 7px 2px rgb(146 146 146 / 55%), 0 0 10px 1px rgb(146 146 146 / 55%)
}

.neon_blue {
  border: 1px solid #02B2FE;
  box-shadow: inset 0 0 7px 2px #02b2fe87, 0 0 10px 1px #02b2fe87
}


.neon_pink {
  border: 1px solid #F323C1;
  box-shadow: inset 0 0 7px 2px #F323C187, 0 0 10px 1px #F323C187
}


.neon_orange {
  border: 1px solid #FD6301;
  box-shadow: inset 0 0 7px 2px #FD630187, 0 0 10px 1px #FD630187
}


.neon_yellow {
  border: 1px solid #E1B703;
  box-shadow: inset 0 0 7px 2px #E1B70387, 0 0 10px 1px #E1B70387
}


/*Roadmap*/

.roadmap {
  /*margin-left: 3.3rem;*/
  color: #BDBDBD;
}

#roadmap_box {
  padding: 1.9375rem 3.75rem 2.5625rem;
}

#roadmap_box > p {
  font-size: 1.25rem;
  margin-left: 5em;
  color: #BDBDBD;
  max-width: 46.875rem;
  margin-top: 1rem;
}

.roadmap header h3 {
  font-weight: bold;
  font-size: 2rem;
  height: 2rem;
  padding: 0 0.4rem;
  display: inline-block;
  vertical-align: middle;
  /*line-height: inherit;*/
  margin: 0;
}

.roadmap ul {
  font-size: 1.5rem;
  /*border-left: 2px solid #B75CFF;*/
  margin-top: 0.5rem;
  margin-left: 5.9rem;
  line-height: 2.375rem;
  list-style: none;

}

.roadmap ul li {
  margin-top: 1.625rem;
  text-shadow: rgb(182 92 255 / 94%) -10px 0 24px;
  padding-left: 2.8rem;
  position: relative;

}

.roadmap ul li:before {
  content: '';
  background: url("../../public/images/icons/walrus_active.png") no-repeat center center;
  background-size: contain;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: middle;
  margin-right: 1.2rem;
}

.roadmap > header {
  position: relative;
  /*left: -1.2rem;*/
}


.roadmap .roadmap_logo {
  max-width: 5rem;
  margin-right: 0.7rem;
}

.roadmap .r_check_icon {
  width: 2rem;
}

.roadmap.not-active .roadmap_logo {
  filter: grayscale(100%);
  opacity: 0.6;
}

#roadmap_box_table{
  font-size: 1.5rem;
  /*border-left: 2px solid #B75CFF;*/
  margin-top: 0.5rem;
  margin-left: 5.9rem;
}

#roadmap_box_table .arrow_col{
  width: 2rem;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  align-items: center;
}

#roadmap_box_table .roadmap_row{
  display: flex;
}

#roadmap_box_table .roadmap_txt{
  flex-grow: 1;
  line-height: 2.375rem;
  text-shadow: rgb(182 92 255 / 94%) -10px 0 24px;
  margin-left: 1.2rem;
  min-height:5rem;
}

#roadmap_box_table .arrow_line{
  margin-top: 0.4rem;
  margin-bottom: 0.25rem;
  width: 2px;
  height: 100%;
  /*background-color: red;*/
  /*border-left: 2px dotted #B75CFF;*/
  background: url("../../public/images/icons/dashed.png") repeat-y center ;

}

#roadmap_box_table .arrow_line_icon{
  margin-top: 0.4rem;
  flex-shrink: 0;
  flex-grow: 0;


}

/*Team Box*/
.team_box-mobile {
  flex-direction: column;
  color: white;
}


#team_desktop {
  padding: 1.5rem 0.4rem 4.625rem;
  justify-content: center;
}

#team_desktop > section {
  justify-content: center;
  flex-wrap: wrap;
  display: flex;
  max-width: 56.5rem;
}

.team_box {
  text-align: center;
  margin: 1.7rem auto 0;
  flex: 0 0 25%
}

.team_box > h5, .team_box-mobile > h5 {
  color: #B75CFF;
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 1rem;
}

.team_box > span {
  font-weight: 500;
  font-size: 1.125rem;
  color: #BDBDBD;

}

.team_box > img {
  display: inline-block;
  width: 100%;
  max-width: 10.625rem;
}


/*Footer*/

footer {
  min-height: 21.125rem;
  margin-top: 4rem;
  padding-top: 2rem;
  padding-bottom: 0;
  background: rgb(49, 59, 63);
  /*background: url("../../public/images/main/bg-big.png") no-repeat center top;*/
  /*background: linear-gradient(0deg, rgba(49, 59, 63, 1) 0%, rgba(20, 20, 20, 1) 100%);*/
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.65) 100%), url(../../public/images/bg/bg-main.jpg) no-repeat center top;;
  background-size: cover;
}

#footer_info {
  text-align: center;
  margin: 0 auto;
  /*padding: 1rem 2rem;*/
}

#footer_social {
  max-width: 6.8125rem;
  margin: 0 auto;
  font-size: 1.875rem;
  line-height: initial;
}

.white-link {
  color: white;
  transition: all .2s ease-in-out;
}

.white-link:hover {
  color: #fff !important;
  transform: scale(1.25);
}

#footer_social a:hover img {
  filter: invert(61%) sepia(97%) saturate(283%) hue-rotate(196deg) brightness(112%) contrast(99%);
}

#footer_info p {
  font-size: 1.375rem;
  color: white;
  margin-top: 1.6875rem;
}

#footer_info > img {
  margin-bottom: 4rem;
  margin-top: 1.3125rem;
  max-width: 19.125rem;
}

#nft_slider {
  padding: 1.5rem 0 0.5rem;
}

#nft_slider .splide__slide > img {
  width: 100%;
  border-radius: 1rem;
}

/*.swiper {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    font-size: 1.5rem;*/
/*    color: white;*/
/*}*/

.splide__slide {
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

/*.splide__slide:hover {*/
/*    cursor: grab;*/
/*}*/

.cursor {
  cursor: pointer;
}

.splide__track{
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.splide__pagination{
  position: static !important;
  margin-top: 1.5rem !important;
}
.splide__pagination__page {
  background: none !important;
  border-radius: 50%;
  border: 1px solid white !important;
}

.splide__pagination__page.is-active {
  transform: scale(1.2) !important;
}

.splide__pagination li {
  margin-right: 3px !important;
  margin-left: 3px !important;
}

.nav-bar-spide {
  font-size: 1rem;
  color: white;
}


.splide__pagination__page.is-active {
  opacity: 1 !important;
  background: white !important;
}

.wrapper-pagination .splide__pagination {
  position: static !important;
  width: auto !important;
  padding: 0.25rem 1.5rem 0 1.5rem;
}


/*MobileFix*/
.splide__slide {
  text-align: center;
  max-width: 100%;
}

.splide__slide img {
  display: inline-block;
  /*width: 100%;*/
  height: auto;
  /*object-fit: cover;*/
  /*max-width: 37.5rem;*/
}


#tusk_supply_count_mobile {
  font-size: 1rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.09) 50%, rgba(255, 255, 255, 0) 50%);
  border-radius: 0.625rem;
  padding: 1.2rem 1.5rem;
  text-align: center;
  border: 1px solid #B75CFF
}

#tusk_supply_count_mobile h4 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #B75CFF;
}

#tusk_box_desc_mobile {
  font-size: 1.125rem;
  color: #BDBDBD;
  text-align: center;
  margin-top: 1.375rem;
}

::-moz-selection {
  background: #B75CFF;
  color: #fff;
}

::selection {
  background: #B75CFF;
  color: #fff;
}

/* ---------------------------------------------------------------- */
/* 08.Header
/* ---------------------------------------------------------------- */
.header {
  padding: 0;
  -webkit-transition: all 400ms cubic-bezier(0.65, 0, 0.35, 1);
  transition: all 400ms cubic-bezier(0.65, 0, 0.35, 1);
  background: rgba(0, 0, 0, 0.75);
}

.header .navbar-brand {
  padding: 0;
  color: #fff;
  margin-right: 0;
  max-width: 14.75rem;
  flex-shrink: 0;
}

.header .navbar-brand img {
  display: none;
}

.header .navbar-brand img.logo-transparent {
  display: block;
}

.header .navbar-btn-resp .primary-button {
  margin-left: 20px;
  padding: 0.5rem 1.8rem;
  white-space: nowrap;
  /*word-wrap: ;*/
}

.header .nav-item .nav-link,.header .nav-item .nav-page {
  color: #ECF1F0;
  font-size: 1rem;
  /*font-weight: 500;*/
  padding: 31px 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: block;
  position: relative;
  line-height: 28px;
  /*opacity: 0.8;*/
}

.header .nav-item .nav-link:after,.header .nav-item .nav-page:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  background: #B75CFF;
}

.header .nav-item .nav-link.active,.header .nav-item .nav-page.active {
  color: #B75CFF;
  font-weight: bold;
}

.header .nav-item .nav-link:not(.active):after,.header .nav-item .nav-page:not(.active):after {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  z-index: 7;
  content: '';
  position: absolute;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
  background: #B75CFF;
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}

.header .nav-item .nav-link:not(.active):hover:after ,.header .nav-item .nav-page:not(.active):hover:after{
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.header .nav-item:hover > a {
  color: #fff;
  opacity: 1;
}


.header .navbar-toggler {
  outline: none;
  border: none;
  border-radius: 0;
  padding: 0 1rem;
  background-color: #B75CFF;
  align-self: stretch;
  min-height: 3.75rem;
}

.header .navbar-toggler .togler-icon-inner span {
  height: 3px;
  margin: 5px;
  width: 30px;
  display: block;
  background: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/*.header .navbar-toggler .togler-icon-inner span:nth-child(2) {*/
/*    width: 20px !important;*/
/*    margin-left: auto;*/
/*}*/

.header .navbar-toggler .togler-icon-inner span:last-child {
  margin-bottom: 0;
}

.header .navbar-toggler[aria-expanded="true"] .togler-icon-inner span:nth-child(1) {
  -webkit-transform: rotate(-45deg) translate(-5px, 6px);
  transform: rotate(-45deg) translate(-5px, 6px);
}

.header .navbar-toggler[aria-expanded="true"] .togler-icon-inner span:nth-child(2) {
  opacity: 0;
}

.header .navbar-toggler[aria-expanded="true"] .togler-icon-inner span:nth-child(3) {
  -webkit-transform: rotate(45deg) translate(-5px, -6px);
  transform: rotate(45deg) translate(-5px, -6px);
}

.header .login-btn-mobile, .header .search-btn-mobile {
  display: none;
}

/* Header Shrink */
.header-shrink {
  border-bottom: none;
  padding: 0;
  width: 100%;
  position: fixed;
  z-index: 555;
  background: #000;
  -webkit-box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
  -webkit-animation: stickyMenu 1s ease forwards;
  animation: stickyMenu 1s ease forwards;
}

.header-shrink .nav-item:hover .nav-link,.header-shrink .nav-item:hover .nav-page {
  color: #B75CFF;
}

.header-shrink .nav-item .default-nav-btn {
  border-color: #B75CFF;
}

.header-shrink .nav-item .default-nav-btn span {
  z-index: 1;
  color: #B75CFF;
}

.header-shrink .nav-item .default-nav-btn:hover {
  color: #fff;
}

.header-shrink .nav-item .default-nav-btn:hover span {
  color: #fff;
}

.header-shrink .nav-item .default-nav-btn:hover:after {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.header-shrink .nav-item .default-nav-btn:after {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -4;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  position: absolute;
  background: #B75CFF;
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-transition: -webkit-transform 400ms cubic-bezier(0.65, 0, 0.35, 1);
  transition: -webkit-transform 400ms cubic-bezier(0.65, 0, 0.35, 1);
  transition: transform 400ms cubic-bezier(0.65, 0, 0.35, 1);
  transition: transform 400ms cubic-bezier(0.65, 0, 0.35, 1), -webkit-transform 400ms cubic-bezier(0.65, 0, 0.35, 1);
}

.header-shrink .nav-item .nav-link ,.header-shrink .nav-item .nav-page {
  color: #fff;
  opacity: 1;
}

.header-shrink .nav-item .nav-link.active, .header-shrink .nav-item .nav-link:hover,.header-shrink .nav-item .nav-page.active, .header-shrink .nav-item .nav-page:hover  {
  color: #B75CFF;
  font-weight: bold;
}

.header-shrink .nav-item .nav-link:after,.header-shrink .nav-item .nav-page:after {
  background: #B75CFF;
}

.header-shrink .nav-item .nav-link:not(.active):after, .header-shrink .nav-item .nav-page:not(.active):after {
  background: #B75CFF;
}



.header-shrink .navbar-toggler .togler-icon-inner span {
  background-color: #fff;
}

.nav-socials, #footer_social {
  font-size: 1.37rem;
  line-height: initial;
}

.nav-socials > a, #footer_social > a {
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  padding-top: 0.2rem;
}


.navbar-toggler:focus {
  box-shadow: none;
}

.inner-page{
  margin-top: 7.31rem;
  min-height: calc(100vh - 31.75rem);
}

#lore{
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: #BDBDBD;
  padding: 72px 32px 60px 235px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) -0.47%, rgba(255, 255, 255, 0.024) 34.83%), no-repeat left center url("../../public/images/bg/west_mask.png");
}
@media only screen and (max-width: 1300.98px) {

  .navbar-nav {
    padding-left: 1.5rem;
  }
}

@media only screen and (max-width: 1200.98px) {
  .header .navbar-brand img.logo-transparent {
    width: 10.5625rem;
    height: 2.6875rem;
    position: relative;
    left: 0.125rem;
  }

  .navbar-nav {
    padding-left: 1.7rem;
  }

  .header .navbar-btn-resp .primary-button{
    margin-left: 11px;
  }

  .header .nav-item .nav-link,  .header .nav-item .nav-page {
    font-size: 0.9rem;
    padding: 31px 11px;
  }


  .header .navbar-brand {
    max-width: 10.5rem;
  }



  .perk_line > img {
    margin-right: 0.7rem;
    max-width: 2.7rem;
  }

  #welcome p {
    padding: 0 6rem;

  }

  #wl_drop {
    font-size: 1.3rem;
  }

  #wl_date {
    font-size: 2.5rem;
  }

  .small_line {
    height: 0.25rem;
    margin-bottom: 3rem;
    margin-top: 3rem;
  }

  #perk_line_wrapper {
    padding-left: 8rem;
  }

  #tusk_box_desc {
    font-size: 1.3rem;
  }
  .team_box > h5 {
    font-size: 1.2rem;
  }


  #lore{
    padding: 72px 30px 60px 175px;
  }

}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    flex-direction: row-reverse;
  }
}

@media (max-width: 991.98px) {

  .navbar {
    justify-content: space-between;
  }


  .header .container {
    max-width: 100%;
    padding: 0;
  }

  /*.header .navbar-brand {*/
  /*    padding: 10px 0 10px 15px;*/
  /*}*/
  .header .main-menu .navbar-nav {
    /*max-height: 400px;*/
    overflow: hidden;
    overflow-y: auto;
    padding-left: 0;
  }

  .header .main-menu .navbar-nav .nav-item:last-child .nav-link {
    border-bottom: 0;
  }

  .header .main-menu .navbar-nav .nav-link, .header .main-menu .navbar-nav .nav-page {
    color: #fff;
    padding: 1.15rem 1.5rem !important;
    -webkit-transition: all 0.25s linear;
    transition: all 0.25s linear;
    opacity: 1;
    border-bottom: 1px solid #eee;
  }

  .header .main-menu .navbar-nav .nav-link:after,   .header .main-menu .navbar-nav .nav-page:after {
    display: none;
  }

  .header .main-menu .navbar-nav .nav-link.active ,  .header .main-menu .navbar-nav .nav-page.active{
    background: rgba(183, 92, 255, 0.25);
    color: #fff !important;
    border-color: #B75CFF;
  }

  .header .main-menu .navbar-nav .nav-link:not(.active):hover,  .header .main-menu .navbar-nav .nav-page:not(.active):hover  {
    background: #B75CFF;
    color: #fff !important;
    border-color: #B75CFF;
  }

  .header .main-menu .nav-item .dropdown-menu {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: rgba(248, 14, 14, 0.1);
  }

  .header .main-menu .nav-item .dropdown-menu .dropdown-item:hover {
    background: #B75CFF;
  }

  .header .dropdown-toggle:before {
    top: 27px !important;
    right: 20px !important;
  }

  .header .dropdown-toggle[aria-expanded="true"]:before {
    top: 11px !important;
    -webkit-transform: rotate(180deg) !important;
    transform: rotate(180deg) !important;
  }

  .header .search-btn-mobile {
    display: block;
    position: absolute;
    top: 32px;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 16px;
    right: 60px;
  }

  .header.header-shrink .search-btn-mobile {
    color: #000000;
  }

  .navbar-btn-resp {
    display: none !important;
  }

  .nav-socials {
    /*align-self: start !important;*/
    padding: 1.15rem 0 ;
  }

  /* Header Shrink */
  .header-shrink .nav-item:hover .nav-link ,.header-shrink .nav-item:hover .nav-page {
    color: #B75CFF;
  }

  .header-shrink .nav-item .nav-link,  .header-shrink .nav-item .nav-page {
    color: #fff;
  }

  .header-shrink .nav-item .nav-link.active, .header-shrink .nav-item .nav-link:hover,  .header-shrink .nav-item .nav-page.active, .header-shrink .nav-item .nav-page:hover {
    color: #B75CFF;
  }

  .header-shrink .nav-item .nav-link:after,.header-shrink .nav-item .nav-page:after {
    background: #B75CFF;
  }

  .header-shrink .nav-item .nav-link:not(.active):after , .header-shrink .nav-item .nav-page:not(.active):after{
    background: #B75CFF;
  }

  .header-shrink .togler-icon-inner span {
    background-color: #000000;
  }

  .navbar-collapse {
    border-top: 1px solid white;
  }


  #welcome p {
    padding: 0 1rem;

  }

  #welcome {
    font-size: 1.9rem;
  }
  .team_box > img{
    max-width: 8rem;
  }

  #rarity_mobile_slider{
    margin-bottom: 2rem;
  }

  #rarity_mobile_slider .rarity_item >img{
    max-width: 20.4375rem;
  }

  #rarity_mobile_slider .rarity_item >div{
    max-width: 20.4375rem;
    margin: 0.75rem auto;
    width: 100%;
  }

  .rarity_item > div{
    border-radius: 0.5rem;
  }
}

@media (max-width: 767.98px) {
  #welcome {
    font-size: 1.75rem;
    padding-bottom: 2rem;
  }

  #welcome p {
    font-size: 1.75rem;
    line-height: 2.5rem;
    /*font-weight: 600;*/
  }

  #wl_date {
    font-size: 1.75rem;
    background: none;
    height: auto;
    line-height: normal;
  }

  #wl_date:before, #wl_date:after {
    content: none;
  }

  .small_line {
    margin-bottom: 2.8rem;
    margin-top: 2.6rem;
  }


  #mint_box {
    width: 22rem;
    /*min-height: 10rem;*/
  }

  #mint_button {
    padding: 0.4rem 4rem;
    /*font-size: 2rem;*/
  }


  #wl_drop {
    font-size: 1rem;
  }

  #whitelist_box .v_line {
    border-right: none;
    border-bottom: 1px solid #4F4F4F;
    margin-bottom: 2rem;
  }

  #tusk_supply_container {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  #tusk_box_desc {
    font-size: 1rem;
    margin-top: 1.5rem;
    padding: 0;
    align-self: start;

  }

  .h_check_des {
    padding:  0 1rem 0 1rem;
    font-size: 1rem;
  }

  .h_check > img {
    display: none;
  }

  .h_check > div {
    padding-left: 2rem;
    margin-left: -1rem;
    width: 100%;
    /*background: linear-gradient(270deg, rgba(183, 92, 255, 0) 0%, #671AE4 82.5%), no-repeat 95% center/ 1.5rem url("../../public/images/icons/circle_check.png");*/
    font-size: 1.125rem;
    font-weight: bold;
    color: white;
  }

  #whitelist_box {
    padding: 1.1rem 1.1rem 2rem 0;
  }

  #whitelist_box h4 {
    font-size: 1.4rem;
  }

  .h_check {
    margin-left: 0;
  }


  .roadmap > header {
    /*left: -1.5rem;*/
    /*white-space: nowrap;*/
    text-align: center;
  }

  .roadmap header h3 {
    font-weight: bold;
    font-size: 1.5rem;
    margin-top: 0.6rem;
    margin-bottom: 2.7rem;
    height: 1rem;
    display: block;
  }

  .roadmap ul {
    font-size: 1.2rem;
    padding-left: 0;
    margin-left: 0;
  }
  #roadmap_box_table{
    margin-left: 0;
    font-size: 1.1rem;

  }
  #roadmap_box_table .roadmap_txt{
    line-height: 2rem ;
  }

  #roadmap_box {
    padding: 1rem 1rem 2rem;
  }

  #roadmap_box > p {
    margin-left: 0;
    font-size: 1rem;
  }

  .roadmap .r_check_icon {
    width: 1.1rem;
  }

  .roadmap .roadmap_logo {
    max-width: 5.5rem;
  }

  #perk_line_wrapper {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .info_header {
    margin-bottom: 0.5rem;
    margin-top: 3rem;
    font-size: 1.5rem;
  }

  .perk_line {
    font-size: 1rem;
    font-weight: normal;
    margin-top: 1.25rem;

  }

  #nft_slider {
    padding: 0;
  }

  .info_box {
    padding: 1rem 1rem 2rem;
  }

  .perk_line > img {
    max-width: 2rem;
  }

  #roadmap_box_table .arrow_line_icon,#roadmap_box_table .arrow_line{
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
  }
  #rarity_box > .info_box{
    padding: 1rem;
  }

  #team_mobile_slider{
    padding-top: 1.5rem;
  }
  .team_box-mobile img{
    width:13.625rem;
    max-width: 100%;

  }

  .rarity_check_des{
    font-size: 1rem;
  }

  #lore{
    padding: 72px 30px 60px 75px;
    font-size: 20px;
  }

}


@media only screen and (max-width: 576px) {


  .info_header {
    font-size: 1.5rem;
  }

  .roadmap header h3 {
    white-space: normal;
    padding: 0;
    /*font-size: 0.8rem;*/
    /*overflow-wrap: break-word;*/
  }


  #mint_box {
    width: 20.5rem;
    /*min-height: 8rem;*/
    /*padding: 1.7rem;*/

  }

  .header .navbar-brand {
    padding: 10px 2px 10px 12px !important;
  }

  .wrapper-pagination .splide__pagination {
    padding: 0;
  }




  #tusk_supply_count_mobile {
    font-size: 1rem;
  }


  .roadmap ul {
    font-size: 0.76rem;
    line-height: 1rem;
    padding-left: 1.6rem;
  }

  #perk_line_wrapper {
    padding-left: 1rem;
    padding-right: 0;
  }

  #lore{
    padding: 52px 30px 50px 45px;
    font-size: 18px;
  }
}

/*end of 576*/

@media only screen and (max-width: 300px) {
  .container{
    padding: 0;
  }

  .header .navbar-brand img.logo-transparent {
    position: static;
  }
}
