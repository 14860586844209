.container {
    /*background: url(5.png), linear-gradient(90deg, rgba(255, 255, 255, 0.1) -0.47%, rgba(255, 255, 255, 0.024) 34.83%);*/
    min-height: 22.9375rem;
    border-radius: 10px;
    border: 1px solid #4D4D4D;
    display: flex;
    flex-direction: column;
    background: url("../../../public/images/page/walruses_min_page.png") no-repeat center top, #040404;
    /*background-size:cover;*/
    align-items: center;
    padding-bottom: 1.3rem;
}
.info_header{
    margin-bottom: 1.2rem;
}
.walletStatus{
    color: #606061;
    border: 2px solid #292829;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 1.125rem;
    font-weight: normal;
    padding: 0.5rem;
    /*min-width: 201px;*/
    height: 41px;
    line-height: 41px;
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    align-content: center;
    top:11px;
}
.walletStatus.active{
    color: #77C66E;
    border-color: #77c66e70;
}
.walletStatusMobile{
    position: static;
    min-height: 3.25rem;
    justify-content: center;

    background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) -0.47%, rgba(255, 255, 255, 0.024) 34.83%);
}
.h3 {
    color: #BDBDBD;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-top: 2.25rem;
}

.h5 {
    color: #BDBDBD;
    font-size: 1.125rem;
    text-align: center;
}

.h5_2 {
    color: #BDBDBD;
    font-size: 1.25rem;
    text-align: center;
    margin-top: 0.4rem;
}

.h5_3{
    color: #BDBDBD;
    font-size: 30px;
    text-align: center;
    margin-top: 1.8rem;
}

.h5_4{
    color: #B75CFF;
    font-size: 28px;
    margin-top: 1.8rem;
    font-weight: bold;
}

.viewBtn{
    background: #333333;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    color: white;
    padding: 15px 39px;
    margin: 0 auto 70px;
    display: inline-block;
    transition: all .2s ease-in-out;
}


.viewBtn:hover{
    background: #ab52fb;
    color: white;
}


.counter_wrapper {
    border-radius: 10px;
    border: 1px solid #B75CFF;
    min-width: 22rem;
    min-height: 5rem;
    padding: 1rem;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38)), linear-gradient(180deg, rgba(255, 255, 255, 0.09) 50%, rgba(255, 255, 255, 0) 50%);

}

.counter_title {
    color: #4F4F4F;
    font-size: 0.875rem;
}

.counter_digit {
    color: #B75CFF;
    font-size:2.3125rem;
    font-weight: bold;
    line-height: 1.6rem;
}

.connectWalletBtn{
   font-size: 1.5rem;
    color: white;
    background: linear-gradient(214.02deg, #B75CFF 6.04%, #671AE4 92.95%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-top: 0.55rem;
    margin-bottom: 1rem;
    min-width: 20rem;
    text-align: center;
    padding: 0.4rem;
    font-weight: 500;
}

.disableBtn{
    background: #333333 !important;
    cursor: not-allowed  !important;
    color : #BDBDBD !important;
}

.ether_icon > img{
    max-width:47px;
    margin-top:20px;
    transition: all .2s ease-in-out;
}

.ether_icon:hover > img{
    transform: scale(1.1);
}

.mintCountWrapper{
    border-radius: 10px;
    border: 1px solid #B75CFF;
    min-width: 20rem;
    display: flex;
    min-height:5rem;
    color: #B75CFF;
    font-size:3.25rem;
    font-weight:bold;
    margin-top: 0.9rem;
    margin-bottom: 1.1rem;
    justify-content: space-around;
    user-select: none;
    -webkit-user-select: none;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38)), linear-gradient(180deg, rgba(255, 255, 255, 0.09) 50%, rgba(255, 255, 255, 0) 50%);
}
.mintCountWrapper > div{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.mintCountBtn{
    cursor: pointer;
    transition:  all 0.1s;

}

.mintCountBtn:hover{
    fill: #d6afef;
    opacity: 0.8
}

.mintCountBtn:hover path{
    fill: #d6afef;
}

.connectWalletBtn:hover,.connectWalletBtn:focus{
    cursor: pointer;
    color: white;
}

.transition1Enter {
    opacity: 0;
    transform: scale(0.8);
}
.transition1EnterActive {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}
.transition1Exit {
    opacity: 1;
    max-height: 0px;
}
.transition1ExitActive {
    opacity: 0;
    transform: scale(0.9);
    transition: max-height 190ms,opacity 200ms, transform 200ms;
}


@media only screen and (max-width: 576px){
    .inner_page{
        margin-top: 6rem;
    }
    .connectWalletBtn,.counter_wrapper{
        min-width: 90%;
    }
    .connectWalletBtn{
        margin-top: 0.8rem;
    }

    .counter_digit {
        font-size:2rem;
    }
    .container{
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) -0.47%, rgba(255, 255, 255, 0.024) 34.83%);
        min-height: 20rem;
        padding-bottom: 0.7rem;

    }

    .info_header{
        margin-top: 0.625rem;
        margin-bottom: 0.5rem;
    }

    .h5_2 {
        font-size: 1.125rem;
    }

    .h3{
        font-size: 1.75rem;
        margin-top: 1.25rem;
    }

    .connectWalletBtn {
        font-size: 1.125rem;
        margin-top: 0.4rem;

    }

    .mintCountWrapper{
        margin-top: 0.6rem;
    }
}
