/* Generated by Glyphter (http://www.glyphter.com) on  Tue Jan 18 2022*/
@font-face {
    font-family: 'RezaMirjah';
    src: url('../../public/fonts/glyphter-font/fonts/RezaMirjah.eot');
    src: url('../../public/fonts/glyphter-font/fonts/RezaMirjah.eot?#iefix') format('embedded-opentype'),
         url('../../public/fonts/glyphter-font/fonts/RezaMirjah.woff') format('woff'),
         url('../../public/fonts/glyphter-font/fonts/RezaMirjah.ttf') format('truetype'),
         url('../../public/fonts/glyphter-font/fonts/RezaMirjah.svg#RezaMirjah') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
   font-family: 'RezaMirjah';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
    vertical-align: middle;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-discord:before{content:'\0041';}
.icon-twitter:before{content:'\0042';}
.icon-next:before{content:'\0043';}
.icon-prev:before{content:'\0044';}
.icon-menu-close:before{content:'\0045';}
.icon-menu-m:before{content:'\0046';}
.icon-check_gray:before{content:'\0047';}
.icon-open-sea:before{content:'\0048';}
